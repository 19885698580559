import React from 'react';
import './mentors.css';

const MentorCard = ({ name, specialization, experience, education, image }) => {
  return (
    <div className="mentor-card">
      
        <img src={image} alt={`${name}`} />
      
    </div>
  );
};

export default MentorCard;

