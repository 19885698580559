import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './mentors.css'; // Import your custom CSS
import mentor1 from '../../assets/Mentor01.png';
import Mentor2 from '../../assets/Mentor02.png';
import Mentor3 from '../../assets/Mentor03.png';
import Mentor4 from '../../assets/Mentor04.png';
import Mentor5 from '../../assets/Mentor05.png';
import Mentor6 from '../../assets/Mentor06.png';
import Mentor7 from '../../assets/Mentor07.png';
import Mentor8 from '../../assets/Mentor08.png';
import Mentor9 from '../../assets/Mentor09.png';
import Mentor10 from '../../assets/Mentor10.png';
import Mentor11 from '../../assets/Mentor11.png';
import Mentor12 from '../../assets/Mentor12.png';
import Mentor13 from '../../assets/Mentor13.png';
import Mentor14 from '../../assets/Mentor14.png';
import MentorCard from './MentorCard';

const CardSlider = () => {

  // const mentorData = [
  //   {
  //     name: 'Ishwar Chaudhary',
  //     specialization: 'Economics',
  //     experience: '3+ years of teaching experience explaining economic concepts to aspiring students.',
  //     education: 'M.Sc In Economics IIT Roorkee,P.hD BITS Pilani',
  //     image: Mentor1
  //   },
  //   {
  //     name: 'Avichal Sharma',
  //     specialization: 'Economics & Mathematics',
  //     experience: 'With his dual specialism and 7+ years of experience, he adopts an avant-garde and interdisciplinary teaching style.',
  //     education: 'M.Sc & P.hD In Economics, IIT Roorkee',
  //     image: Mentor2
  //   },
  //   {
  //     name: 'Vipul Chaudhary',
  //     specialization: 'Physics',
  //     experience: '3+ years of making complicated physical concepts familiar to all.',
  //     education: 'M.Sc Physics IIT Roorkee',
  //     image: Mentor3
  //   },
  //   {
  //     name: 'Shivam Trivedi',
  //     specialization: 'Physics',
  //     experience: 'With over 5 years of physics experience, he can provide both theoretical and applied insights.',
  //     education: 'M.Sc Physics IIT Roorkee,P.hD IIT Delhi',
  //     image: Mentor4
  //   },
  //   {
  //     name: 'Parveen Kumar',
  //     specialization: 'Mathematics',
  //     experience: '5+ years of experience simplifying the complex concepts of mathematics for students.',
  //     education: 'M.Sc in Mathematics NIT Jalandhar , Ex PW Mathematics faculty',
  //     image: Mentor5
  //   },
  //   {
  //     name: 'Vikram Deswal',
  //     specialization: 'Chemistry',
  //     experience: 'With over 5 years of experience, he offers complete guidance for various competitive exams.',
  //     education: 'M.Sc Chemistry MDU Rohtak,M.Tech IIT Delhi,IIT JAM,CSIR NET,IIT GATE (Ex PW faculty)',
  //     image: Mentor6
  //   },
  //   {
  //     name: 'Baiju P. Anthony',
  //     specialization: 'Psychology & English',
  //     experience: 'Twelve years of experience in offering varied academic insights in Psychology and English.',
  //     education: 'MA English, MA Sociology: Central University Pondicherry, M.Phil Philosophy: Delhi University, P.hD in Psychology (BITS Pilani)',
  //     image: Mentor7
  //   },
  //   {
  //     name: 'Anjali',
  //     specialization: 'Mathematics & Psychology',
  //     experience: 'Uniquely qualified and 5+ years of extensive teaching experience catering to a variety of learning needs.',
  //     education: 'M.Sc in Mathematics,B.Ed in Special Education from MDU Rohtak',
  //     image: Mentor8
  //   },
  //   {
  //     name: 'Lokesh Joya',
  //     specialization: 'Math & Reasoning',
  //     experience: 'Over 5 years of experience in mathematics and reasoning. Being an engineer gives him a practical approach to problem solutions.',
  //     education: 'B.Tech (Mechanical Engineering)',
  //     image: Mentor9
  //   },
  //   {
  //     name: 'Ashish Kumar',
  //     specialization: 'General Knowledge & General Science & General Studies',
  //     experience: 'More than 3 years of varied experience in physics and a vast knowledge base make him especially fit to guide students in general studies.',
  //     education: 'M.Sc Physics',
  //     image: Mentor10
  //   },
  //   {
  //     name: 'Banita Yadav',
  //     specialization: 'English',
  //     experience: 'Six years of immense experience in preparing students for different competitive-level examinations.',
  //     education: 'MA (IGNOU)',
  //     image: Mentor11
  //   },
  //   {
  //     name: 'Parveen Saini',
  //     specialization: 'Economics & Mathematics',
  //     experience: 'Expertise in providing quality mathematics and economics guidance for 2+ years.',
  //     education: 'M.Sc IIT Roorkee',
  //     image: Mentor12
  //   },
  //   {
  //     name: 'Amit Kumar Yadav',
  //     specialization: 'Mathematics',
  //     experience: 'With five years of advanced mathematical experience, he assures you of top-notch mathematics instruction.',
  //     education: 'M.Sc in Mathematics IIT Mandi, CSIR NET',
  //     image: Mentor13
  //   },
  //   {
  //     name: 'Ashish Kumar',
  //     specialization: 'Physics',
  //     experience: '10+ years of expertise is enhancing candidates\' understanding of physics fundamentals.',
  //     education: 'M.Sc Physics,CSIR NET 19',
  //     image: Mentor14
  //   }
  // ];
  
  const mentorData = [
    { image: mentor1},
    { image: Mentor2},
    { image: Mentor3},
    { image: Mentor4},
    // { image: Mentor5},
    { image: Mentor6},
    { image: Mentor7},
    { image: Mentor8},
    { image: Mentor9},
    { image: Mentor10},
    { image: Mentor11},
    { image: Mentor12},
    { image: Mentor13},
    { image: Mentor14}
  ]
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Show 2 images at a time
    slidesToScroll: 1,
    nextArrow: <button className="slider-btn next-btn">Next</button>,
    prevArrow: <button className="slider-btn prev-btn">Prev</button>,
    responsive: [
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className='slider-container'>
      <h1>Meet Our Expert Mentors</h1>
      <Slider {...settings}>
      {mentorData.map((mentor, index) => (
      <img src={mentor.image} alt="mentor" />
         
        ))}
    </Slider>
    </div>
  );
};

export default CardSlider;
