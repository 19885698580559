import React, { useState } from 'react';
import './Blog8.css';
import image1 from '../../assets/Blog8_1.png'; 

const Blog3 = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  const blogContent = (
    <div>
      <p>If you plan to go to an Indian college, then probably you have heard of the CUET – Common University Entrance Test. The test, introduced by the National Testing Agency (NTA), has streamlined the process of getting into colleges by making it more convenient and accessible. Here is all that you need to know about CUET and how it impacts your college journey.</p>

      <h3>What is CUET?</h3>
      <p>CUET is a national-level entrance exam for undergraduate admissions to central universities throughout India. Before CUET, students had to sit for several entrance examinations for each university they were applying to. With CUET, however, students can apply to many universities based on one test score.</p>
      <img src={image1} alt="image 1" />

      <h3>How Does CUET Impact Your College Admissions?</h3>
      <h4>1. Streamlined Application Process</h4>
      <p>The main advantage of the CUET system over other systems is its simplicity of application procedures. Before the introduction of CUET, students had to complete individual application forms and sit for different entrance tests for each university. With the implementation of CUET, this no longer applies.</p>

      <h4>2. Equal Opportunities Nationwide</h4>
      <p>CUET provides equal opportunities to students across India. Earlier, students from different states had to fulfill different eligibility requirements or follow different syllabi, which created inconsistencies in the admission process for universities. CUET standardizes the exam pattern, thereby making sure that all students are evaluated based on the same criteria regardless of their location.</p>

      <h4>3. Focus on Merit</h4>
      <p>CUET shifts the focus from Class 12 board marks to a merit-based selection process. Earlier, universities depended heavily on Class 12 scores for admissions, which did not necessarily reflect a student's potential or performance in the domain they wished to pursue. Being a dedicated entrance exam, CUET assesses students' performances in particular subjects relevant to their course, thus providing a fairer and more transparent selection process.</p>

      <h4>4. More Course Options</h4>
      <p>CUET opens the door to several undergraduate programs at different universities. It offers a subject-specific exam for the streams of Humanities, Science, Commerce, and Vocational courses that perfectly match your aspirations.</p>

      <h3>How to Prepare for CUET</h3>
      <p>Success in CUET demands an effective preparation strategy. The following are some helpful tips that might aid your success:</p>
      <ul>
        <li><strong>Know the Syllabus:</strong> The syllabus for CUET can be reviewed through the official website. Emphasize the vital areas directly.</li>
        <li><strong>Practice with Mock Tests:</strong> Regularly take mock tests to get used to the exam format and help develop speed and accuracy.</li>
        <li><strong>Manage Your Time:</strong> Develop your time management skills by taking timed mock tests so that you can complete the paper within the allotted time.</li>
        <li><strong>Stay Updated:</strong> Check the official NTA website regarding changes in exam patterns and important dates.</li>
      </ul>

      <h3>Conclusion</h3>
      <p>CUET has transformed college admissions in India by making the process simpler and more uniform. It consolidates several entrance examinations into one, allowing students to apply to different universities without the burden of taking multiple exams. While preparing for CUET, concentrate on your strengths, strategize your studies accordingly, and seize this opportunity to get admitted to your desired university.</p>
    </div>
  );

  const blogSnippet = (
    <div>
      <p>
        If you plan to go to an Indian college, then probably you have heard of the CUET – Common University Entrance Test. The test, introduced by the National Testing Agency (NTA), has streamlined the process of getting into colleges by making it more convenient and accessible...
      </p>
    </div>
  );

  return (
    <div className="blog-container">
      <h2>What is CUET and How It Impacts Your College Admissions?</h2>
      <div className="blog-content">
        {expanded ? blogContent : blogSnippet}
      </div>
      <button onClick={toggleReadMore}>
        {expanded ? 'Show Less' : 'Read More'}
      </button>
    </div>
  );
};

export default Blog3;
