import teamImg_1 from "../../assets/team_img_1.png";

export const teamData = [
  {
    Img:teamImg_1,
    name:"Manas Ranjan Jena",
    designation:"Founder & CEO"
  },
  {
    Img:teamImg_1,
    name:"Manas Ranjan Jena",
    designation:"Founder & CEO"
  },
  {
    Img:teamImg_1,
    name:"Manas Ranjan Jena",
    designation:"Founder & CEO"
  },
  {
    Img:teamImg_1,
    name:"Manas Ranjan Jena",
    designation:"Founder & CEO"
  },
  {
    Img:teamImg_1,
    name:"Manas Ranjan Jena",
    designation:"Founder & CEO"
  },
  {
    Img:teamImg_1,
    name:"Manas Ranjan Jena",
    designation:"Founder & CEO"
  },
]