// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/careers_banner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carrers_banner{
  width: 100%;
  height: 70vh;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.carrers_banner h4{
  font-size: 64px;
  font-weight: 700;
  font-family: "Montserrat";
  line-height: 80px;
  color: #FFFFFF;
}

.carrers_banner h5{
  font-size: 40px;
  font-weight: 600;
  font-family: "Montserrat";
  line-height: 50px;
  color: #FFFFFF;
}

/* ----------Media query----------- */

@media (max-width:900px) and (min-width:600px) {
  .carrers_banner h4 {
    font-size: 40px;
    line-height: 70px;
  }
  .carrers_banner h5 {
    font-size: 28px;
    line-height: 40px;
  }
}

 @media (max-width:600px) and (min-width:300px) {
   .carrers_banner{
     height: 50vh;
     padding: 0% 3%;
     display: flex;
     justify-content: center;
     align-items: center;
   }
  .carrers_banner h4 {
    font-size: 28px;
    line-height: 53px;
  }
  .carrers_banner h5 {
    font-size: 23px;
    line-height: 32px;
  }
 }`, "",{"version":3,"sources":["webpack://./src/styles/Careers.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,6DAAyD;EACzD,sBAAsB;EACtB,2BAA2B;EAC3B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,iBAAiB;EACjB,cAAc;AAChB;;AAEA,qCAAqC;;AAErC;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;CAEC;GACE;KACE,YAAY;KACZ,cAAc;KACd,aAAa;KACb,uBAAuB;KACvB,mBAAmB;GACrB;EACD;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;CACD","sourcesContent":[".carrers_banner{\r\n  width: 100%;\r\n  height: 70vh;\r\n  background: url(\"../assets/careers_banner.png\") no-repeat;\r\n  background-size: cover;\r\n  background-position: center;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n}\r\n\r\n.carrers_banner h4{\r\n  font-size: 64px;\r\n  font-weight: 700;\r\n  font-family: \"Montserrat\";\r\n  line-height: 80px;\r\n  color: #FFFFFF;\r\n}\r\n\r\n.carrers_banner h5{\r\n  font-size: 40px;\r\n  font-weight: 600;\r\n  font-family: \"Montserrat\";\r\n  line-height: 50px;\r\n  color: #FFFFFF;\r\n}\r\n\r\n/* ----------Media query----------- */\r\n\r\n@media (max-width:900px) and (min-width:600px) {\r\n  .carrers_banner h4 {\r\n    font-size: 40px;\r\n    line-height: 70px;\r\n  }\r\n  .carrers_banner h5 {\r\n    font-size: 28px;\r\n    line-height: 40px;\r\n  }\r\n}\r\n\r\n @media (max-width:600px) and (min-width:300px) {\r\n   .carrers_banner{\r\n     height: 50vh;\r\n     padding: 0% 3%;\r\n     display: flex;\r\n     justify-content: center;\r\n     align-items: center;\r\n   }\r\n  .carrers_banner h4 {\r\n    font-size: 28px;\r\n    line-height: 53px;\r\n  }\r\n  .carrers_banner h5 {\r\n    font-size: 23px;\r\n    line-height: 32px;\r\n  }\r\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
