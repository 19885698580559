import React from 'react';
import "./OurMotto.css";
import { Grid } from '@mui/material';

const OurMotto = () => {
  return (
    <>
      <section className='motto_main_container'>
          <Grid container className='motto_container'>
            <Grid item sm={3} xs={12} className='motto_about_box'>
              <div className="number_box"><span>01</span></div>
              <h4>Oriented to student needs and success</h4>
              <p>We prioritize our students and strive to offer solutions that cater to their specific educational and career goals.</p>
            </Grid>

            <Grid item sm={3} xs={12} className='motto_about_box'>
              <div className="number_box"><span>02</span></div>
              <h4>Growing globally and staying up to date</h4>
              <p>We stay current with the latest industry trends and are dedicated to expanding our global reach.</p>
            </Grid>

            <Grid item sm={3} xs={12} className='motto_about_box'>
              <div className="number_box"><span>03</span></div>
              <h4>Open-minded from every perspective</h4>
              <p>We value diverse viewpoints and fresh ideas. Our open-minded approach fosters a culture of creativity and innovation.</p>
            </Grid>
            
          </Grid>

          <div className="our_motto_box_wrapper">
            <h4>Our Motto</h4>

            <div className="our_moto_box_card">
              <h4>“Empowering Minds & Shaping the Future with Bold Ideas”</h4>
            </div>
          </div>
      </section>
    </>
  )
}

export default OurMotto;
