/** @format */

import React, { useState } from "react";
import "../Footer/Footer.css";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillInstagram } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // React Router for navigation
// require("dotenv").config({ path: "../../dev.env"})
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import freedemologo from "../../assets/freedemologo.png";
import footerlogo from "../../assets/logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling effect
    });
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate(); // useNavigate hook to redirect

  const handleSubmit = (e) => {
    e.preventDefault();

    // You can add form validation or submission logic here
    if (name && email && phone) {
      const scriptURL =
        "https://script.google.com/macros/s/AKfycbyNgaBfcHm0F9dHG3rVj00YQplHcgjV7HQWdzhxp6tYJ-3LB1nOmptNx7lNrhrTgpEbTg/exec";
      const hardcodedData = new FormData();
      hardcodedData.append("name", name);
      hardcodedData.append("email", email);
      hardcodedData.append("phone", phone);

      fetch(scriptURL, {
        method: "POST",
        body: hardcodedData,
      })
    .then((response) => {console.log("Success!", response)
        setMessage("Your information has been saved!"); 
        setName(""); 
         setEmail("");  
         setPhone("");
    }
      )
        .catch((error) => console.error("Error!", error.message));
      // After successful submission, redirect to the desired URL
      // window.location.href = "https://courses.abaskolearning.com/";
    } else {
      alert("Please fill in all fields.");
    }
  };

  return (
    <>
      <div className="footer-container">
        {/* topside */}
        <div className="ccntr">
          <div className="free-demo-card">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                gap: "20px",
                paddingTop: "40px",
              }}
            >
              <div style={{ marginTop: "20px" }}>
                {/* <Typography
                style={{ color: "#000000", fontSize: "50px", width: "300px" }}
              >
                GET A <span style={{ color: "#066CD3" }}>FREE DEMO CLASS</span>{" "}
                NOW
              </Typography> */}
                <h2
                  style={{
                    color: "#000000",
                    fontSize: "30px",
                    width: "300px",
                    fontFamily: "Martel Sans",
                    lineHeight: "30px",
                    // marginBottom: "50px",
                  }}
                >
                  GET A{" "}
                  <span style={{ color: "#066CD3", fontFamily: "Martel Sans" }}>
                    FREE DEMO CLASS
                  </span>{" "}
                  NOW
                </h2>

                <form className="form-continer" onSubmit={handleSubmit}>
                  <div className="form-header">
                    <p>For more queries, please enter your details below:</p>
                  </div>
                  <div className="input-container">
                    <TextField
                      label="Name"
                      variant="outlined"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                      className="input-field"
                    />

                    <TextField
                      label="Email"
                      variant="outlined"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                      className="input-field"
                    />

                    <TextField
                      variant="outlined"
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      style={{ marginBottom: "30px" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+91</InputAdornment>
                        ),
                        // endAdornment: (
                        //   <InputAdornment position="end">
                        //     <IconButton className="send-button">
                        //       <SendIcon className="send-icon" />
                        //     </IconButton>
                        //   </InputAdornment>
                        // ),
                      }}
                      fullWidth
                      className="input-field"
                    />
                  </div>
                  <center>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: "30px" }}
                      className="submit-button"
                    >
                      Submit
                    </Button>
                  </center>
                  {message && <p>{message}</p>} {/* Display the message */}
                </form>
              </div>
              {/* <div>
                <img
                  src={freedemologo}
                  style={{
                    width: "220px",
                    height: "220px",
                    objectFit: "cover",
                  }}
                  className="freedemo-images"
                />
              </div> */}
            </div>
          </div>
        </div>
        {/* bottom footer */}
        <div className="footerbottom-main">
          <div className="footerbottom-text-main">
            <div>
              <img src={footerlogo} className="footerbottom-mainimage" />
            </div>
            <div className="footerbottom-secondone">
              <ul className="footerbottom-ulisecond">
                <li className="footerbottom-lisecond first-li">Support</li>
                <li className="footerbottom-lisecond">Getting started</li>
                <li className="footerbottom-lisecond">Help center</li>
                <li className="footerbottom-lisecond">Report a bug</li>
              </ul>
            </div>
            <div className="footerbottom-3text">
              <ul className="footerbottom-ulthird">
                <li className="footerbottom-li3 first-li2">Legal</li>
                <li className="footerbottom-li3">
                  <Link
                    to="/privacypolicy"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="footerbottom-li3">
                  <Link
                    to="/cookiespolicy"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Cookie Policy
                  </Link>
                </li>
                <li className="footerbottom-li3">
                  <Link
                    to="/termscondition"
                    style={{ textDecoration: "none", color: "#fff" }}
                    onClick={handleClick}
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li className="footerbottom-li3">
                  <Link
                    to="/refundpolicy"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Refund Policy
                  </Link>
                </li>
                <li className="footerbottom-li3">
                  <Link
                    to="/shippingpolicy"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Shipping Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footerbottom-threetext">
              <ul className="footerbottom-downloadul">
                <li className="download-li downli2">Downloads</li>
                <li className="download-li">iOS</li>
                <li className="download-li">
                  <a
                    href="https://play.google.com/store/apps/details?id=app.abaskolearning.learn"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="download-li"
                  >
                    Android
                  </a>
                </li>
                <li className="download-li">Mac</li>
                <li className="download-li">Windows</li>
              </ul>
            </div>
            <div className="newsletter-bodyfooter">
              <p className="newfoot-subtext">Subscribe to our newsletter</p>
              {/* <p className="news-footertext">
               
              </p> */}
              <div className="main-newsfoor">
                <input className="input-sub" placeholder="Enter Your Email" />
                <button className="button-subnews">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
        <div className="footerdown-main">
          <h3 className="react-out-text">Reach out to us on</h3>
          <div className="footerdown-social">
            <a
              href="https://www.facebook.com/people/Abasko-Learning/61566812814284"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <FaFacebookF color="#fff" fontSize={23} />{" "}
            </a>
            <a
              href="https://wa.me/message/QOKE2UZV34QPL1"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <IoLogoWhatsapp color="#fff" fontSize={23} />{" "}
            </a>{" "}
            <a
              href="https://www.instagram.com/abaskolearning/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <AiFillInstagram color="#fff" fontSize={23} />{" "}
            </a>
            <a
              href="https://www.youtube.com/@CUETAbasko"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <FaYoutube color="#fff" fontSize={23} />{" "}
            </a>{" "}
            <a
              href="https://x.com/abaskolearning"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <FaTwitter color="#fff" fontSize={23} />{" "}
            </a>{" "}
            <a
              href="https://www.linkedin.com/company/104793291/admin/dashboard/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <FaLinkedin color="#fff" fontSize={23} />{" "}
            </a>
          </div>
          <h4 className="react-out-text-two">© Copyright 2024</h4>
        </div>
      </div>
    </>
  );
};
export default Footer;
