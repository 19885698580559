import { Grid } from '@mui/material';
import React from 'react';
import { teamData } from "./OurTeamData.js";
import "./OurTeam.css";
import { Link } from 'react-router-dom';
import { CiLinkedin } from "react-icons/ci";

import about_lower_card_img_1 from "../../assets/about_lower_section_card_img1.png"

import about_lower_card_img_2 from "../../assets/about_lower_section_card_img2.png"

import about_lower_card_img_3 from "../../assets/about_lower_section_card_img3.png"

import about_lower_card_img_4 from "../../assets/about_lower_section_card_img4.png"

import about_lower_card_img_5 from "../../assets/about_lower_section_card_img5.png"

const OurTeam = () => {
  return (
    <>
      <section className='our_team_section'>
        <div className="heading_box">
          <h4>Our Team</h4>
          <p>In addition to our training programs, HyperNxt Academy leads in creating cutting-edge infrastructure and Centers of Excellence. Our initiatives include</p>
        </div>

        <Grid container className='our_team_main_container'>
          {
            teamData?.map((item, i) => (
              <Grid key={i} item sm={3.5} xs={12} className='our_team_card'>
                <div className="our_team_img_box">
                  <img src={item.Img} alt="item.Img" />
                </div>
                <h4>{item.name}</h4>
                <h5>{item.designation}</h5>
                
                
                <button className="our_tem_btn_box"><Link>Connect <CiLinkedin className='our_tem_linkedin_icon'/>
                </Link></button>
                
              </Grid>
            ))
          }
        </Grid>
      </section>

      <section className="about_lower_section_card">
            <Grid container className='about_lower_card_container'>
              <Grid item sm={5.7} xs={12} className='about_lower_ard_box box_ab_1'>
                <img src={about_lower_card_img_1} alt="about_lower_card_img_1" />
                <h4>
                100% Live Session and 1:1 Mentorship
                </h4>
              </Grid>

              <Grid item sm={5.7} xs={12} className='about_lower_ard_box box_ab_2'>
              <img src={about_lower_card_img_2} alt="about_lower_card_img_2" />
                <h4>
                Best-in-class delivery experience through offline mode, ensuring hands-on training.
                </h4>
              </Grid>
            </Grid>

            <Grid container className='about_lower_card_container'>
              <Grid item sm={3.7} xs={12} className='about_lower_ard_box box_ab_3'>
                <img src={about_lower_card_img_1} alt="about_lower_card_img_1" />
                <h4>
                Industry recognized courses & certificates
                </h4>
              </Grid>

              <Grid item sm={3.7} xs={12} className='about_lower_ard_box box_ab_4'>
              <img src={about_lower_card_img_2} alt="about_lower_card_img_2" />
                <h4>
                Industry standard projects
                </h4>
              </Grid>

              <Grid item sm={3.7} xs={12} className='about_lower_ard_box box_ab_5'>
              <img src={about_lower_card_img_2} alt="about_lower_card_img_2" />
                <h4>
                Most affordable in the market
                </h4>
              </Grid>
            </Grid>
      </section>
    </>
  )
}

export default OurTeam;
