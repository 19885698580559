// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-container {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
  }
  
  .blog-container h2 {
    margin-bottom: 20px;
  }
  
  .blog-content p {
    color: #333;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .blog-content ul {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .blog-content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px 0;
  }
  
  button {
    background-color: #1e90ff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #4169e1;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Blog9/Blog9.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;IACvC,YAAY;EACd;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".blog-container {\n    background: #f9f9f9;\n    padding: 20px;\n    border-radius: 5px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    margin: 20px;\n  }\n  \n  .blog-container h2 {\n    margin-bottom: 20px;\n  }\n  \n  .blog-content p {\n    color: #333;\n    font-size: 16px;\n    line-height: 1.5;\n  }\n  \n  .blog-content ul {\n    padding-left: 20px;\n    margin-bottom: 20px;\n  }\n  \n  .blog-content img {\n    max-width: 100%;\n    height: auto;\n    display: block;\n    margin: 20px 0;\n  }\n  \n  button {\n    background-color: #1e90ff;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n    margin-top: 10px;\n  }\n  \n  button:hover {\n    background-color: #4169e1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
