import React, { useState } from 'react';
import './Blog7.css';
import image1 from '../../assets/Blog7_1.png'; 
import image2 from '../../assets/Blog7_2.png'; 

const Blog2 = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  const blogContent = (
    <div>
      <p>The Common University Entrance Test (CUET) is one of the most significant exams for students aspiring to get into top central universities in India. With only a week left before the exam, it's crucial to focus on strategic preparation rather than cramming new information. In this blog, we’ll guide you through the best practices to follow in the final week leading up to the CUET exam for maximum performance.</p>
      <img src={image1} alt="image 1" />
      <h3>1. Revise Key Concepts and Important Topics</h3>
      <p>The first step in your final week of preparation is to revise key concepts that are critical for the CUET exam. At this stage, avoid learning new topics as it could overwhelm you. Instead, go over the following:</p>
      <ul>
        <li>Previous year’s question papers: These will give you a solid understanding of the exam pattern and the types of questions that frequently appear.</li>
        <li>Important chapters: Focus on the chapters that carry more weightage, as well as topics where you feel less confident. Make sure to revise Maths, English, General Knowledge, and the specific subject for your stream.</li>
        <li>Formulae and Notes: If you’ve made notes or highlighted formulas, now is the time to go over them quickly.</li>
      </ul>
      <p>Tip: Use mind maps or flashcards for quick revision, as these can help you retain information more effectively.</p>

      <h3>2. Practice Time Management</h3>
      <p>In the CUET exam, time management is crucial. You need to develop a strategy to answer questions quickly and efficiently. Here's how to improve your time management:</p>
      <ul>
        <li>Simulate real exam conditions: Take mock tests under timed conditions. This will help you build stamina and get used to the time pressure of the actual exam.</li>
        <li>Set time limits per section: Practice solving each section within a set time limit. For example, give yourself 25 minutes for the English section and 45 minutes for the General Knowledge section, based on your strengths.</li>
      </ul>
      <p>Tip: Make sure to practice speed-reading for English comprehension to save time and increase accuracy.</p>

      <h3>3. Take Mock Tests</h3>
      <p>Mock tests are one of the best ways to prepare for the CUET exam in the final week. By regularly practicing with CUET mock tests, you get a clear picture of your readiness and identify areas where you need improvement.</p>
      <ul>
        <li>Review each mock test: After completing a mock test, don’t just check your score. Go over the questions you got wrong and understand why you made those mistakes.</li>
        <li>Take full-length mock tests: These help you develop exam endurance and improve your focus.</li>
      </ul>
      <p>SEO Tip: Use keywords like “CUET mock test”, “CUET practice papers”, and “CUET sample questions” in your search for practice materials.</p>

      <h3>4. Revise General Knowledge and Current Affairs</h3>
      <p>General Knowledge and Current Affairs are important sections in the CUET exam. In the final week, concentrate on the following:</p>
      <ul>
        <li>Latest news: Focus on current events, sports, politics, economics, and other trending topics. You can use apps, websites, and daily news briefs to stay updated.</li>
        <li>Static GK: Revise important facts, figures, and historical events that are frequently asked in the exam.</li>
      </ul>
      <p>Tip: Use apps like “General Knowledge Quiz” or “Current Affairs Daily” to keep your knowledge fresh.</p>

      <h3>5. Focus on Mental and Physical Health</h3>
      <p>Your mental and physical health is just as important as your academic preparation. Here’s what you can do:</p>
      <ul>
        <li>Sleep well: Prioritize sleep, especially in the last few days before the exam. Aim for at least 7-8 hours of sleep per night to ensure you’re well-rested and sharp during the exam.</li>
        <li>Eat healthy: Stay hydrated and eat nutritious food. Avoid junk food or anything that might affect your concentration or energy levels.</li>
        <li>Take short breaks: Long study sessions without breaks can lead to burnout. Take a 5-10 minute break every hour to refresh your mind.</li>
      </ul>
      <p>Tip: Engage in stress-relief activities like meditation or deep breathing exercises to keep calm and focused.</p>

      <h3>6. Review Exam Day Instructions</h3>
      <p>In the final week, don’t forget to review the exam day instructions. This includes:</p>
      <ul>
        <li>Admit card: Ensure your admit card is ready, and you have all necessary documents (ID, photographs, etc.).</li>
        <li>Exam center location: Check the location of your exam center and plan your route in advance. Aim to reach the center at least 30 minutes before the exam starts.</li>
        <li>What to carry: Verify the allowed items, such as pens, pencils, and a water bottle. Do not carry any unauthorized items like phones or electronic gadgets.</li>
      </ul>

      <h3>7. Stay Positive and Confident</h3>
      <p>Finally, stay positive and maintain confidence in your preparation. The last week can feel intense, but maintaining a calm and focused mindset will help you perform your best.</p>
      <ul>
        <li>Visualize success: Spend a few minutes each day visualizing yourself succeeding in the exam. Positive thinking can reduce anxiety and boost your confidence.</li>
        <li>Avoid distractions: Limit your time on social media and avoid unnecessary distractions that can negatively affect your preparation.</li>
      </ul>
      <img src={image2} alt="image 2" />
      <h3>Conclusion</h3>
      <p>The final week before the CUET exam is crucial for refining your skills and boosting your confidence. By revising key concepts, practicing time management, taking mock tests, focusing on your health, and following the exam day instructions, you’ll be well-prepared to perform your best.</p>
    </div>
  );

  const blogSnippet = (
    <div>
      <p>The Common University Entrance Test (CUET) is one of the most significant exams for students aspiring to get into top central universities in India...</p>
    </div>
  );

  return (
    <div className="blog-container">
      <h2>What to Do a Week Before the CUET Exam: A Complete Guide for Success</h2>
      <div className="blog-content">
        {expanded ? blogContent : blogSnippet}
      </div>
      <button onClick={toggleReadMore}>
        {expanded ? 'Show Less' : 'Read More'}
      </button>
    </div>
  );
};

export default Blog2;
