// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-l0fmuu-MuiPaper-root-MuiAccordion-root{
  margin-bottom: 10px;
  height: 64px;
}

.css-l0fmuu-MuiPaper-root-MuiAccordion-root {
  border-radius: 10px !important;
}
.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root{
  height: 100%;
}

.css-eqpfi5-MuiAccordionSummary-content{
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.css-ahj2mt-MuiTypography-root{
  font-size: 17px !important;
  font-weight: 500 !important;
  font-family: "Inter" !important;
  color: #151515;
}

/* -------Media query------- */

@media (max-width:600px) and (min-width:300px) {
  .css-ahj2mt-MuiTypography-root{
    font-size: 13px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Accordian/Accordian.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,8BAA8B;AAChC;AACA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,+BAA+B;EAC/B,cAAc;AAChB;;AAEA,8BAA8B;;AAE9B;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".css-l0fmuu-MuiPaper-root-MuiAccordion-root{\r\n  margin-bottom: 10px;\r\n  height: 64px;\r\n}\r\n\r\n.css-l0fmuu-MuiPaper-root-MuiAccordion-root {\r\n  border-radius: 10px !important;\r\n}\r\n.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root{\r\n  height: 100%;\r\n}\r\n\r\n.css-eqpfi5-MuiAccordionSummary-content{\r\n  height: 100%;\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n}\r\n\r\n.css-ahj2mt-MuiTypography-root{\r\n  font-size: 17px !important;\r\n  font-weight: 500 !important;\r\n  font-family: \"Inter\" !important;\r\n  color: #151515;\r\n}\r\n\r\n/* -------Media query------- */\r\n\r\n@media (max-width:600px) and (min-width:300px) {\r\n  .css-ahj2mt-MuiTypography-root{\r\n    font-size: 13px !important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
