import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './reviews.css'; // Import your custom CSS
import Mentor1 from '../../assets/mentor1.png';

const CardSlider = () => {

  const reviews = [
    {
      name: "Abhay Sharma",
      title: "JEE Aspirant",
      img: "https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg",
      desc: "ABASKO Learning has been a game-changer for my JEE prep. The mentors explain tough concepts so clearly, especially in Physics. The practice tests and doubt-clearing sessions have boosted my confidence. Highly recommend it to serious aspirants!"
    },
    {
      name: "Riya Verma",
      title: "NEET Aspirant",
      img: "https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg",
      desc: "ABASKO Learning has been a game-changer for my JEE prep. The mentors explain tough concepts so clearly, especially in Physics. The practice tests and doubt-clearing sessions have boosted my confidence. Highly recommend it to serious aspirants!"
    },
    {
      name: "Aman Gupta",
      title: "Engineering Student",
      img: "https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg",
      desc: "ABASKO Learning has been a game-changer for my JEE prep. The mentors explain tough concepts so clearly, especially in Physics. The practice tests and doubt-clearing sessions have boosted my confidence. Highly recommend it to serious aspirants!"
    },
    {
      name: "Sneha Patil",
      title: "JEE Aspirant",
      img: "https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg",
      desc: "ABASKO Learning has been a game-changer for my JEE prep. The mentors explain tough concepts so clearly, especially in Physics. The practice tests and doubt-clearing sessions have boosted my confidence. Highly recommend it to serious aspirants!"
    },
    {
      name: "Arjun Singh",
      title: "JEE Aspirant",
      img: "https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg",
      desc: "ABASKO Learning has been a game-changer for my JEE prep. The mentors explain tough concepts so clearly, especially in Physics. The practice tests and doubt-clearing sessions have boosted my confidence. Highly recommend it to serious aspirants!"
    },
    {
      name: "Isha Mehra",
      title: "NEET Aspirant",
      img: "https://example.com/isha.jpg",
      desc: "The resources and mentorship offered by ABASKO have been a crucial part of my NEET preparation."
    }
  ];

  return (
    <>
    <h1 className='review_heading'>Our Student Reviews</h1>
    <div className="student_reviews">
      <div className="reviews">
        {reviews.map((review, index) => (
          <div className="review" key={index}>
            <div className="name">
              <img src={review.img} alt={review.name} />
              <div className="details">
                <h4>{review.name}</h4>
                <p>{review.title}</p>
              </div>
            </div>
            <div className="desc">
              <p>{review.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default CardSlider;
