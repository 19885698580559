import placement_partner_deloitte from "../../assets/placement_partner_Delotitte_logo.png";

import placement_partner_capegimini from "../../assets/placement_partner_Capegemini_logo.png";

import placement_partner_akamai from "../../assets/placement_partner_Akamai_logo.png";

import placement_partner_philips from "../../assets/placement_partner_Philips_logo.png";

import placement_partner_wipro from "../../assets/placement_partner_Wipro_logo.png";

import placement_partner_acko from "../../assets/placement_partner_Acko_logo.png";



export const marqueeData = [
  {
    img:placement_partner_deloitte
  },
  {
    img:placement_partner_capegimini
  },
  {
    img:placement_partner_akamai
  },
  {
    img:placement_partner_philips
  },
  {
    img:placement_partner_wipro
  },
  {
    img:placement_partner_acko
  }
]