// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Martel+Sans:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FAQ{
  margin: 100px 0;
  margin: 50px;
  max-width: 900px;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 2px 2px 7px rgb(185, 185, 185);
  h1{
    font-size: 25px;
    margin-bottom: 0px;
    font-family: "Martel Sans";
  }
  .container{
    border: 1px solid rgb(141, 141, 141);
    margin: 30px 0;
    border-radius: 10px;
    padding: 15px;
    h4{
      font-size: 13px;
      font-family: "Martel Sans";
    }
    p{
      font-size: 11px;
      margin-top: 15px;
      line-height: 18px;
    }
  }
  
}
/* ---Media Query---- */

@media (min-width:600px) and (max-width:980px) {


}

@media (max-width:600px) {
  
}`, "",{"version":3,"sources":["webpack://./src/components/FAQ/FAQ.css"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,0CAA0C;EAC1C;IACE,eAAe;IACf,kBAAkB;IAClB,0BAA0B;EAC5B;EACA;IACE,oCAAoC;IACpC,cAAc;IACd,mBAAmB;IACnB,aAAa;IACb;MACE,eAAe;MACf,0BAA0B;IAC5B;IACA;MACE,eAAe;MACf,gBAAgB;MAChB,iBAAiB;IACnB;EACF;;AAEF;AACA,uBAAuB;;AAEvB;;;AAGA;;AAEA;;AAEA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@400;700&display=swap');\r\n\r\n.FAQ{\r\n  margin: 100px 0;\r\n  margin: 50px;\r\n  max-width: 900px;\r\n  padding: 30px;\r\n  border-radius: 20px;\r\n  box-shadow: 2px 2px 7px rgb(185, 185, 185);\r\n  h1{\r\n    font-size: 25px;\r\n    margin-bottom: 0px;\r\n    font-family: \"Martel Sans\";\r\n  }\r\n  .container{\r\n    border: 1px solid rgb(141, 141, 141);\r\n    margin: 30px 0;\r\n    border-radius: 10px;\r\n    padding: 15px;\r\n    h4{\r\n      font-size: 13px;\r\n      font-family: \"Martel Sans\";\r\n    }\r\n    p{\r\n      font-size: 11px;\r\n      margin-top: 15px;\r\n      line-height: 18px;\r\n    }\r\n  }\r\n  \r\n}\r\n/* ---Media Query---- */\r\n\r\n@media (min-width:600px) and (max-width:980px) {\r\n\r\n\r\n}\r\n\r\n@media (max-width:600px) {\r\n  \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
